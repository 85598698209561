// DO NOT CHANGE ANYTHING INSIDE.
// THIS FILE IS AUTOGENERATED.
// RUN `npm run generate-types` TO REGENERATE ACTUAL TYPES

/** @deprecated This type is autogenerated from not typed top-level backend response  */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UnnamedPublicCovestingCovTokenTotal_supply200 {}
/** @deprecated This type is autogenerated from not typed top-level backend response  */
export interface UnnamedBars200 {
  data?: number[][];
}
/** @deprecated This type is autogenerated from not typed top-level backend response  */
export interface UnnamedCompetitions200 {
  data?: PublicCompetition[];
}
/** @deprecated This type is autogenerated from not typed top-level backend response  */
export interface UnnamedCountries200 {
  data?: unknown[][];
}
/** @deprecated This type is autogenerated from not typed top-level backend response  */
export interface UnnamedFxBars200 {
  data?: number[][];
}
/** @deprecated This type is autogenerated from not typed top-level backend response  */
export interface UnnamedFxSettings200 {
  data?: PublicTradeSettings[];
}
/** @deprecated This type is autogenerated from not typed top-level backend response  */
export interface UnnamedMarketDescriptions200 {
  data?: SymbolDescription[];
}
/** @deprecated This type is autogenerated from not typed top-level backend response  */
export interface UnnamedMarkets200 {
  data?: MarketTicker[];
}
/** @deprecated This type is autogenerated from not typed top-level backend response  */
export interface UnnamedPublicCovestingAchievementsTop200 {
  data?: Rating[];
}
/** @deprecated This type is autogenerated from not typed top-level backend response  */
export interface UnnamedPublicCovestingRating200 {
  data?: Rating[];
}
/** @deprecated This type is autogenerated from not typed top-level backend response  */
export interface UnnamedPublicCovestingRatingTop200 {
  data?: Rating[];
}
/** @deprecated This type is autogenerated from not typed top-level backend response  */
export interface UnnamedPublicCovestingSettings200 {
  data?: SettingsDto[];
}
/** @deprecated This type is autogenerated from not typed top-level backend response  */
export interface UnnamedPublicCovestingStrategiesStrategyidEquityHistoryTime_frame200 {
  data?: number[][];
}
/** @deprecated This type is autogenerated from not typed top-level backend response  */
export interface UnnamedPublicCovestingStrategiesStrategyidFollowers_countHistoryTime_frame200 {
  data?: number[][];
}
/** @deprecated This type is autogenerated from not typed top-level backend response  */
export interface UnnamedPublicCovestingStrategiesStrategyidFollowers_equityHistoryTime_frame200 {
  data?: number[][];
}
/** @deprecated This type is autogenerated from not typed top-level backend response  */
export interface UnnamedPublicCovestingStrategiesStrategyidProfitabilityHistoryTime_frame200 {
  data?: number[][];
}
/** @deprecated This type is autogenerated from not typed top-level backend response  */
export interface UnnamedPublicCovestingStrategiesStrategyidTradesHistory200 {
  data?: CovOrderSummaryReply[];
}
/** @deprecated This type is autogenerated from not typed top-level backend response  */
export interface UnnamedPublicCovestingStrategiesStrategyidTradesStats200 {
  data?: TradingStat[];
}
/** @deprecated This type is autogenerated from not typed top-level backend response  */
export interface UnnamedPublicCovestingStrategiesStrategyidUsedMarginHistoryTimeframe200 {
  data?: number[][];
}
/** @deprecated This type is autogenerated from not typed top-level backend response  */
export interface UnnamedPublicCovestingStrategiesStrategyidYieldHistoryTime_frame200 {
  data?: number[][];
}
/** @deprecated This type is autogenerated from not typed top-level backend response  */
export interface UnnamedRates200 {
  data?: CurrencyRate[];
}
/** @deprecated This type is autogenerated from not typed top-level backend response  */
export interface UnnamedTickersBySymbols200 {
  data?: MarketTicker[];
}
/** @deprecated This type is autogenerated from not typed top-level backend response  */
export interface UnnamedYieldInfo200 {
  data?: YieldPlanInfo[];
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Completable {}
export enum Blockchain {
  BITCOIN = 'BITCOIN',
  ETHEREUM = 'ETHEREUM',
  BNB_CHAIN = 'BNB_CHAIN',
  TRC = 'TRC',
}
export enum CompetitionStatus {
  PLANNED = 'PLANNED',
  ACTIVE = 'ACTIVE',
  REJECTED = 'REJECTED',
  CLOSING = 'CLOSING',
  CLOSED = 'CLOSED',
}
export enum Currency {
  AUD = 'AUD',
  CAD = 'CAD',
  COP = 'COP',
  HKD = 'HKD',
  INR = 'INR',
  JPY = 'JPY',
  MXN = 'MXN',
  NZD = 'NZD',
  SGD = 'SGD',
  ZAR = 'ZAR',
  CHF = 'CHF',
  GBP = 'GBP',
  USD = 'USD',
  TRY = 'TRY',
  EUR = 'EUR',
  BRL = 'BRL',
  BTC = 'BTC',
  LTC = 'LTC',
  ETH = 'ETH',
  DASH = 'DASH',
  COV = 'COV',
  BCH = 'BCH',
  BTG = 'BTG',
  USDT = 'USDT',
  DGB = 'DGB',
  USDC = 'USDC',
  BNB = 'BNB',
  TRX = 'TRX',
  THB = 'THB',
  VND = 'VND',
  IDR = 'IDR',
  FLOKI = 'FLOKI',
  SHIB = 'SHIB',
  ARB = 'ARB',
  RUNE = 'RUNE',
  LINK = 'LINK',
  FET = 'FET',
  FTM = 'FTM',
  INJ = 'INJ',
  MATIC = 'MATIC',
  TRB = 'TRB',
  PENDLE = 'PENDLE',
  LDO = 'LDO',
  OM = 'OM',
  JUP = 'JUP',
  STX = 'STX',
  CRV = 'CRV',
  UNI = 'UNI',
  GALA = 'GALA',
  MKR = 'MKR',
  W = 'W',
  SLF = 'SLF',
  AAVE = 'AAVE',
  LPT = 'LPT',
  TRU = 'TRU',
  CHZ = 'CHZ',
  GRT = 'GRT',
  MEME = 'MEME',
  YGG = 'YGG',
  RSR = 'RSR',
  RAY = 'RAY',
  ATOM = 'ATOM',
  BAKE = 'BAKE',
  OMNI = 'OMNI',
  '1INCH' = '1INCH',
  APE = 'APE',
  COTI = 'COTI',
  ILV = 'ILV',
  RENDER = 'RENDER',
  SUPER = 'SUPER',
  POL = 'POL',
}
export enum CurrencyType {
  CRYPTO = 'CRYPTO',
  FIAT = 'FIAT',
}
export enum DayOfWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}
export enum MarginType {
  CROSS = 'CROSS',
  ISOLATED = 'ISOLATED',
}
export enum MarketCategory {
  CRYPTO = 'CRYPTO',
  CFD = 'CFD',
  FOREX = 'FOREX',
  ALL = 'ALL',
}
export enum MembershipPlanType {
  STANDARD = 'STANDARD',
  ADVANCED = 'ADVANCED',
  PREMIUM = 'PREMIUM',
  ELITE = 'ELITE',
}
export enum OAuthActionType {
  LOGIN = 'LOGIN',
  REGISTRATION = 'REGISTRATION',
}
export enum OAuthProvider {
  GOOGLE = 'GOOGLE',
  APPLE = 'APPLE',
}
export enum OAuthStepApiModel {
  RECAPTCHA = 'RECAPTCHA',
  HCAPTCHA = 'HCAPTCHA',
  TWO_FACTOR = 'TWO_FACTOR',
  SUCCESS = 'SUCCESS',
  COUNTRY_OF_RESIDENCE = 'COUNTRY_OF_RESIDENCE',
  PHONE_NUMBER = 'PHONE_NUMBER',
}
export enum Side {
  SELL = 'SELL',
  BUY = 'BUY',
}
export enum SocialLinkType {
  PRIMEXBT = 'PRIMEXBT',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TELEGRAM = 'TELEGRAM',
  TWITTER = 'TWITTER',
  LINKEDIN = 'LINKEDIN',
}
export enum StrategyStatus {
  ACTIVATING = 'ACTIVATING',
  ACTIVE = 'ACTIVE',
  LIQUIDATING = 'LIQUIDATING',
  LIQUIDATION_STARTED = 'LIQUIDATION_STARTED',
  LIQUIDATED = 'LIQUIDATED',
}
export enum SymbolCategory {
  CRYPTO = 'CRYPTO',
  FOREX = 'FOREX',
  CFD = 'CFD',
}
export enum TermsType {
  NONE = 'NONE',
  SPECIAL = 'SPECIAL',
  BONUS = 'BONUS',
}
export enum TimeFrame {
  MINUTE = 'MINUTE',
  MIN_10 = 'MIN_10',
  HOUR = 'HOUR',
  DAY = 'DAY',
  MONTH = 'MONTH',
}
export enum TradingPlatform {
  DEVEX = 'DEVEX',
  FX = 'FX',
  MT5 = 'MT5',
}
export enum TransferSystem {
  SYSTEM = 'SYSTEM',
  PORTFOLIO = 'PORTFOLIO',
  CRYPTO = 'CRYPTO',
  FIAT = 'FIAT',
  DEVEXPERTS = 'DEVEXPERTS',
  COVESTING = 'COVESTING',
  BINARY = 'BINARY',
  COV_BONUS = 'COV_BONUS',
  COV_MEMBERSHIP = 'COV_MEMBERSHIP',
  EXCHANGE = 'EXCHANGE',
  REFERRAL = 'REFERRAL',
  WALLET = 'WALLET',
  YIELD = 'YIELD',
  PFX = 'PFX',
  MARKETING = 'MARKETING',
  MT5 = 'MT5',
  CASHBACK = 'CASHBACK',
}
export interface Achievements {
  equity: boolean;
  activity: boolean;
  marginFree: boolean;
  winRatio: boolean;
  turnover: boolean;
}
export interface AchievementsLimits {
  currency: Currency;
  equityLimit: number;
  turnoverLimit: number;
}
export interface AchievementsSettings {
  currencyLimits: AchievementsLimits[];
  activityExistenceLimit: number;
  marginFreeExistenceLimit: number;
  winRatioExistenceLimit: number;
  turnoverExistenceLimit: number;
  activityDaysLimit: number;
  marginFreeLimit: number;
  winRatioLimit: number;
}
export interface Award {
  amount: number;
  additionalAwardAmount?: number;
}
export interface BookBandScale {
  pips: number;
  price: number;
}
export interface BurnedCovTransaction {
  id: number;
  ts: number;
  hash: string;
  transactionUrl: string;
  amount: number;
}
export interface ClientCurrency {
  name: string;
  scale: number;
  tradeMetricsScale: number;
  type?: string;
  description: string;
}
export interface CompactCurrency {
  currency: string;
  scale: number;
}
export interface ConvertRequest {
  from: string;
  to: string;
}
export interface ConvertResponse {
  rate: number;
}
export interface CovBurned {
  totalBurned: number;
  data: BurnedCovTransaction[];
}
export interface CovOrderSummaryReply {
  symbol: string;
  side: Side;
  openPrice: number;
  openDate: string;
  closePrice: number;
  closeDate: string;
  roiOnMargin: number;
}
export interface CovTokenDetails {
  marketCap: number;
  lastPrice: number;
  totalSupply: number;
  totalStaked: number;
  totalBurned: number;
  contract: string;
  contractUrl: string;
}
export interface CurrencyRate {
  from: string;
  to: string;
  rate: number;
}
export interface DailyScheduler {
  id?: number;
  symbolId: number;
  day: DayOfWeek;
  from: LocalTime;
  to: LocalTime;
}
export interface DictionaryResponse {
  currencies: Record<string, ClientCurrency>;
  categories: Record<string, string[]>;
  supportedCurrencies: Currency[];
  symbols: Record<string, DictionarySymbol>;
  symbolCategories: SymbolCategory[];
  symbolCategoriesV2: Record<string, SymbolCategoryV2[]>;
  barSizes: string[];
  bandScales: number[];
  indicativeCurrencies: CompactCurrency[];
  networks: Blockchain[];
  wallets: DictionaryWalletCurrency[];
  transferDestinationSystems: TransferDestination[];
}
export interface DictionarySymbol {
  id: number;
  name: string;
  qtyScale: number;
  priceScale: number;
  base: string;
  quote: string;
  category: string;
}
export interface DictionaryWalletCurrency {
  currency: string;
  type: CurrencyType;
}
export interface FxMarginLevel {
  from?: number;
  to?: number;
  marginPct: number;
  leverage: number;
}
export interface GetLtsResponse {
  lts: number;
}
export interface GetTickers {
  symbols: string[];
}
export interface GetTopTickersResponse {
  data: Ticker[];
  turnoverUsd: number;
}
export interface HistorySelectReply {
  timeFrame: TimeFrame;
  data: unknown[];
}
export interface Indicators {
  maxDrawdown: number;
  maxDrawdown30Days: number;
  winRatio: number;
  winRatio30Days: number;
  profit: number;
  profit180Days?: number;
  profit90Days?: number;
  profit30Days?: number;
  profit7Days?: number;
}
export interface JsonArray {
  list?: unknown[];
  empty?: boolean;
  null?: JsonArray;
}
export interface JsonObject {
  map?: Record<string, unknown>;
  empty?: boolean;
}
export interface LocalTime {
  hour?: number;
  minute?: number;
  second?: number;
  nano?: number;
}
export interface MarketAssetRecap {
  name: string;
  description: string;
  base: string;
  quote: string;
  platform: string;
  category: string;
  price: number;
  priceUSD: number;
  change24h: number;
  highPrice24h: number;
  lowPrice24h: number;
  ask: number;
  bid: number;
  priceScale: number;
  vol24: number;
  vol24Usd?: number;
  volWeek: number;
  volWeekUsd?: number;
  qtyScale: number;
  marketCapInfo?: MarketCapInfo;
  trend: number[];
  tradable: boolean;
  subcategory?: string;
  tags?: string[];
  popularityOrder?: number;
  symbolId: number;
}
export interface MarketCapInfo {
  price: number;
  market_cap: number;
  volume_24h: number;
  volume_usd_24h: number;
  circulating_supply: number;
  max_supply?: number;
  lts: number;
}
export interface MarketRecapResponse {
  data: MarketAssetRecap[];
}
export interface MarketTicker {
  name: string;
  base: string;
  quote: string;
  last: number;
  change: number;
  price_scale: number;
  description: string;
  qty_scale: number;
  open: number;
  turnover: number;
  turnover_usd: number;
  open_interest: number;
  open_interest_btc: number;
  category: MarketCategory;
  tradable: boolean;
}
export interface MembershipInvestmentFees {
  planType: MembershipPlanType;
  traderPercent: number;
  markupPercent: number;
}
export interface OAuthApiResponse {
  step: OAuthStepApiModel;
  sess_id?: string;
  error_code?: string;
  data?: OAuthSuccessData;
}
export interface OAuthCountryOfResidenceApiRequest {
  sessId: string;
  countryOfResidence: string;
}
export interface OAuthHcaptchaApiRequest {
  sessId: string;
  hcaptcha: string;
}
export interface OAuthInitApiRequest {
  provider: OAuthProvider;
  idToken: string;
}
export interface OAuthPhoneNumberApiRequest {
  sessId: string;
  phoneNumber: string;
}
export interface OAuthRecaptchaApiRequest {
  sessId: string;
  recaptcha: string;
}
export interface OAuthSuccessData {
  access_token: string;
  uid: string;
  actionType: OAuthActionType;
}
export interface OAuthTwoFactorApiRequest {
  sessId: string;
  code: string;
}
export interface Object {
  step: string;
  sessId?: string;
  errorCode?: string;
  data?: unknown;
  sess_id?: string;
  error_code?: string;
}
export interface OfferDto {
  levels: OfferLevelDto[];
}
export interface OfferLevelDto {
  level: number;
  fromAmount: number;
  toAmountExcluding?: number;
  managerFeePercent: number;
  brokerFeePercent: number;
}
export interface PublicCompetition {
  id: number;
  name: string;
  status: CompetitionStatus;
  participants: number;
  startAt: string;
  closeAt: string;
  totalAwards: number;
  currency: Currency;
  additionalAwardType?: string;
  totalAdditionalAwards?: number;
  participantsLimit?: number;
  platform: TradingPlatform;
  minTurnover: number;
  minTrades: number;
  awardsDetails: Award[];
  leaderboardSize: number;
  weekly: boolean;
}
export interface PublicFund {
  strategyId: number;
  strategyName: string;
  strategyStatus: StrategyStatus;
  currency: Currency;
  activeFor: number;
  totalYield: number;
  dailyYield?: number;
  managerInvestments: number;
  equity: number;
  investorsEquity: number;
  rating: number;
  description: string;
  minInvestment: number;
  openingDate?: number;
  closingDate?: number;
  investorsCount: number;
  achievements: Achievements;
  indicators: Indicators;
  social: SocialLink[];
  investorsInitialAmount: number;
  depositLimit?: number;
  canInvest: boolean;
  grade?: number;
  verified: boolean;
  offer: OfferDto;
  showTrades: boolean;
  membershipDistribution: MembershipInvestmentFees[];
}
export interface PublicTradeSettings {
  symbol: string;
  tradable: boolean;
  reduceOnly: boolean;
  takerFeePct: number;
  makerFeePct: number;
  financingShortPct: number;
  financingLongPct: number;
  marginLevels: FxMarginLevel[];
  bookBandScales: BookBandScale[];
  leverages: number[];
  defaultMarginType: MarginType;
  defaultLeverage: number;
  minOrderSize: number;
  maxOrderSize: number;
  nextFinancing?: string;
  maxExposureUsd: number;
  maxLeverage: number;
  financingInterval: string;
  category: string;
  base: string;
  quote: string;
  financingTermsType: TermsType;
  feeTermsType: TermsType;
  orderStep: number;
}
export interface Rating {
  strategyId: number;
  strategyName: string;
  currency: Currency;
  totalYield: number;
  dailyYield?: number;
  activeFor: number;
  investorsCount: number;
  performance: number[];
  equity: number;
  followersEquityUsd?: number;
  totalEquity: number;
  managerEquity: number;
  followersEquity: number;
  rating: number;
  achievements: Achievements;
  minInvestment: number;
  investorsInitialAmount: number;
  depositLimit?: number;
  canInvest: boolean;
  grade?: number;
  verified: boolean;
  showTrades: boolean;
}
export interface RegistrationDisclaimer {
  country: string;
  text: string;
  realm: string;
}
export interface RegistrationDisclaimersDictionary {
  data: RegistrationDisclaimer[];
}
export interface SettingsDto {
  currency: Currency;
  minCapital: number;
  defaultOffer?: OfferDto;
  entryFeePercent: number;
  strategyFollowingLimit?: number;
  membershipDistribution: MembershipInvestmentFees[];
}
export interface SimilarSymbolsResponse {
  data: MarketAssetRecap[];
}
export interface SocialLink {
  type: SocialLinkType;
  login: string;
  link: string;
}
export interface SymbolCategoryV2 {
  code: string;
  description: string;
}
export interface SymbolDescription {
  name: string;
  description: string;
  links: Record<string, string[]>;
}
export interface SymbolInfoResponse {
  price: number;
  changes_percent: Record<string, number>;
  market_cap_info?: MarketCapInfo;
}
export interface SymbolSchedulersResponse {
  data: DailyScheduler[];
}
export interface Ticker {
  name: string;
  base: string;
  quote: string;
  description: string;
  price_scale: number;
  qty_scale: number;
  last: number;
  open: number;
  change: number;
  turnover_usd: number;
  history?: number[];
}
export interface TradingStat {
  symbol: string;
  usagePercent: number;
}
export interface TransferDestination {
  system: TransferSystem;
  currencies: string[];
}
export interface YieldPlanInfo {
  currency: Currency;
  membership: string;
  apy: number;
  min: number;
  max: number;
  canInvest: boolean;
}
export type ErrorCodeOauthHcaptcha = 'WRONG_HCAPTCHA';
export type ErrorCodeOauthInit = 'INVALID_ID_TOKEN';
export type ErrorCodeOauthRecaptcha = 'WRONG_RECAPTCHA';
