'use client';

import React, { useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';

import { ChevronDown } from '@/blocks/components/Icons/Chevron';
import { INavItem } from '@/blocks/sections/Header/data/types';
import { useHeaderScrollStates } from '@/blocks/sections/Header/hooks/useHeaderScrollStates';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { LinkI18n } from '@/i18n/routing';
import context from '@/styles/contexts.module.scss';

import { Dropdown } from '../../../Dropdown';
import styles from './styles.module.scss';

export function NavItem(props: {
  navItem: INavItem;
  active?: boolean;
  selected?: boolean;
  className?: classNames.Argument | classNames.ArgumentArray;
  onClick?: (...args: any) => void;
}) {
  const [opened, setOpened] = useState(false);
  const { pageScrolled, pageScrolledDown } = useHeaderScrollStates();
  const bp = useBreakpoint();
  const dropdown = props.navItem?.subitems?.length;
  const Component = props.navItem.href ? (props.navItem.noLangPrefix ? 'a' : LinkI18n) : 'div';

  function onMouseEnterOrLeave(e: React.MouseEvent<HTMLDivElement>) {
    if (bp !== 'desktop' || !dropdown) {
      return;
    }
    setOpened(e.type === 'mouseenter');
  }

  useEffect(() => {
    if (opened && pageScrolledDown) {
      setOpened(false);
    }
  }, [opened, pageScrolledDown]);

  const dropdownData = useMemo(
    () =>
      (props.navItem.hideSidebar
        ? props.navItem.subitems?.map(({ subitems, widget, ...rest }) => rest)
        : props.navItem.subitems) as INavItem[],
    [props.navItem],
  );

  return (
    <div
      className={classNames(
        styles.navItemWrapper,
        context.light,
        opened && styles.navItemOpenedWrapper,
        props.className,
      )}
      onMouseEnter={onMouseEnterOrLeave}
      onMouseLeave={onMouseEnterOrLeave}
    >
      <Component
        // @ts-ignore
        href={props.navItem.href}
        className={classNames(
          styles.navItem,
          props.active && styles.navItemActive,
          props.selected && styles.navItemSelected,
          props.navItem?.subitems?.length && styles.hasDropdown,
        )}
        onClick={(e) => {
          if (props.onClick) {
            props.onClick(e);
          }
        }}
        {...props.navItem.linkProps}
      >
        {props.navItem.title}
        {dropdown ? <ChevronDown key={1} /> : null}
      </Component>
      {dropdown ? <Dropdown opened={opened} items={dropdownData} /> : null}
    </div>
  );
}
