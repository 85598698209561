import classNames from 'classnames';

import { ArrowRight } from '@/blocks/components/Icons';
import { hasSidebar } from '@/blocks/sections/Header/Components/Dropdown/hasSidebar';
import { INavItem } from '@/blocks/sections/Header/data/types';
import { LinkI18n } from '@/i18n/routing';
import typography from '@/styles/typography.module.scss';

import styles from './styles.module.scss';

export function DropdownItem(props: {
  subitem: INavItem;
  active: boolean;
  onClick: React.MouseEventHandler<HTMLElement>;
  onMouseEnter: React.MouseEventHandler<HTMLElement>;
}) {
  const content = (
    <>
      <div className={classNames(styles.icon)}>{props.subitem.icon}</div>
      <div>
        <span className={classNames(typography.bodyM, styles.title)}>
          {props.subitem.title}
          <ArrowRight />
        </span>
        <span className={classNames(typography.bodyS, styles.description)}>{props.subitem.description}</span>
      </div>
    </>
  );

  const Component = props.subitem.href ? (props.subitem.noLangPrefix ? 'a' : LinkI18n) : 'div';

  return (
    <Component
      // @ts-ignore
      href={props.subitem.href}
      className={classNames(
        styles.topLevelSubitem,
        props.active && styles.active,
        hasSidebar(props.subitem) && props.active && styles.hasSidebar,
      )}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      {...props.subitem.linkProps}
    >
      {content}
    </Component>
  );
}
