'use client';

import React, { useCallback, useEffect, useState } from 'react';

import { useLocale } from 'next-intl';

import { getDecimalPlaceCount } from '@/utils/number';
import { ISO4217IncludesCurrency } from '@/utils/string/currency';

import { getMoneyFormatter } from './money-formatter';

export interface FormatPriceProps {
  price: number;
  priceScale?: number;
  currency?: string;
  displaySymbol?: boolean;
  compact?: boolean;
  compactNotationThreshold?: number;
  placeholder?: string;
  signDisplay?: Intl.NumberFormatOptions['signDisplay'];
}

export function FormatPrice({
  price,
  priceScale = 8,
  currency = '',
  displaySymbol = false,
  compact = false,
  compactNotationThreshold = 1000000,
  signDisplay = undefined,
}: FormatPriceProps) {
  const locale = useLocale();
  const compactNotation = Boolean(compact && price >= compactNotationThreshold);

  const createFormatter = useCallback(() => {
    return getMoneyFormatter(
      locale,
      currency,
      priceScale,
      undefined,
      displaySymbol && ISO4217IncludesCurrency(currency),
      compactNotation,
      signDisplay,
    );
  }, [locale, currency, priceScale, displaySymbol, compactNotation]);

  const [formatter, setFormatter] = useState(createFormatter);
  const [formatted, setFormatted] = useState(() => formatter.format(price));

  useEffect(() => {
    const newFormatter = createFormatter();
    setFormatter(newFormatter);
    setFormatted(newFormatter.format(price));
  }, [createFormatter, price]);

  const explicitCurrency = displaySymbol && !ISO4217IncludesCurrency(currency) ? currency : null;

  return <>{[formatted, explicitCurrency].filter(Boolean).join(' ').trim()}</>;
}

interface FormatPriceWithPlaceholderProps extends Omit<FormatPriceProps, 'price'> {
  price: number | null | undefined;
  placeholder?: string;
}

export function FormatPriceWithPlaceholder({
  price,
  placeholder = '--/--',
  ...props
}: FormatPriceWithPlaceholderProps) {
  if (price === undefined || price === null) {
    return <>{placeholder}</>;
  }

  return <FormatPrice price={price} {...props} />;
}

// Wrapper around FormatPrice, just preset of FormatPrice settings
// Default currency is USD
export function FormatAmount({
  value,
  ...options
}: Omit<FormatPriceProps, 'price'> & {
  value: number;
}) {
  return (
    <FormatPrice
      price={value}
      displaySymbol
      currency={'USD'}
      priceScale={getDecimalPlaceCount(value)}
      {...options}
    />
  );
}
